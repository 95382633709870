var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DataTable", {
        attrs: {
          items: _vm.orders,
          fields: [
            {
              key: "orderId",
              label: "ID"
            },
            "playroom",
            "customer",
            "owner",
            "status",
            "amount",
            "start",
            "actions"
          ],
          "table-filter-value": _vm.tableFilterValue,
          "sorter-value": _vm.sorterValue,
          loading: _vm.loading
        },
        on: {
          "update:tableFilterValue": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:table-filter-value": function($event) {
            _vm.tableFilterValue = $event
          },
          "update:sorterValue": function($event) {
            _vm.sorterValue = $event
          },
          "update:sorter-value": function($event) {
            _vm.sorterValue = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CDropdown",
                      {
                        staticClass: "m-2",
                        attrs: {
                          color: "primary",
                          "toggler-text": "",
                          "data-testid": "actions"
                        }
                      },
                      [
                        _c("CDropdownItem", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "pages.admin.AdminOrderIndex.details",
                              expression:
                                "'pages.admin.AdminOrderIndex.details'"
                            }
                          ],
                          attrs: {
                            to: {
                              name: "AdminOrderDetail",
                              params: {
                                orderId: item.id
                              }
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "amount",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("td", [_vm._v(_vm._s(_vm.formatCurrency(item.amount)))])
              ]
            }
          },
          {
            key: "orderId",
            fn: function(ref) {
              var item = ref.item
              return [_c("td", [_vm._v(" " + _vm._s(item.id) + " ")])]
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "td",
                  [_c("OrderStatus", { attrs: { status: item.status } })],
                  1
                )
              ]
            }
          },
          {
            key: "start",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("td", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formatIsoDateTimeTz(item.start, "Pp")) +
                      " "
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("CPagination", {
        attrs: { pages: _vm.pages, activePage: _vm.currentPage },
        on: {
          "update:activePage": function($event) {
            _vm.currentPage = $event
          },
          "update:active-page": function($event) {
            _vm.currentPage = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }